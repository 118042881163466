import Navbar from "pages/components/navbar";
import Sidebar from "pages/components/sidebar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import BillingTable from "./components/billingtable";

const BillingHistory = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
  };
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const controller = new AbortController();
  const getData = async () => {
    try {
      const response = await axiosPrivate.get("/billing/general", {
        signal: controller.signal,
      });
      setData(response.data.data.transactions);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          handleSidebarToggle={handleSidebarToggle}
        />
        <div className="body-wrapper">
          <Navbar handleSidebarToggle={handleSidebarToggle} />

          <div className="container-fluid note-has-grid">
            <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3 ">
                <div className="row align-items-center">
                  <div className="col-9">
                    <h5 className="fw-semibold mb-8">Billing History</h5>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Billing
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="col-3">
                    <div className="text-center mb-n5">
                      <img
                        src="assets/images/breadcrumb/ChatBc.png"
                        alt=""
                        className="img-fluid mb-n4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild">
             <BillingTable 
              data = {data}
              isLoading = {isLoading}
             />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingHistory;
