import { useState, useEffect } from "react";
import Logo from "pages/assets/logos/logo-dark.png";
import ProgressBar from "@badrap/bar-of-progress";
import useAuth from "middlewares/hooks/useAuth";
import axios from "config/axios";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { errorColor } from "services/colors/constants";
import { Link, useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";

const LoginUser = () => {
  const [remeber, setRemember] = useState(false);
  const onRemember = () => {
    remeber === true ? setRemember(false) : setRemember(true);
  };
  const progress = new ProgressBar({ color: "#020f46", size: 4 });
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuth } = useAuth();
  const [disabled, setDisabled] = useState(false);
  const from = location.state?.from?.pathname || "/dashboard";
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onLoginAction = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axios.post("/auth/sessions", data);
      const accesstoken = response?.data?.data?.access_token;
      const refreshtoken = response?.data?.data?.refresh_token;
      const sessionid = response?.data?.data?.session_id;
      setAuth({ sessionid, accesstoken, refreshtoken });
      const verify = {
        refresh: refreshtoken,
        id: sessionid,
        access: accesstoken,
      };
      localStorage["verify"] = JSON.stringify(verify);
      navigate(from, { replace: true });
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        setDisabled(false);
        toast.error("No server response", {
          style: { background: errorColor, color: "#000" },
        });
        progress.finish();
      } else {
        setDisabled(false);
        progress.finish();
        toast.error(error?.response?.data?.messages, {
          style: { background: errorColor, color: "#000" },
        });
      }
    }
  };
  const handlegoogleLogin = async (response) => {
    setDisabled(true);
    const userData = jwtDecode(response.credential);
    const data = JSON.stringify({
      firstname: userData.given_name,
      lastname: userData.family_name,
      email: userData.email,
    });
    const responseGoogle = await axios.post("/auth/google/sessions", data);
    const accesstoken = responseGoogle?.data.data.access_token;
    const refreshtoken = responseGoogle?.data.data.refresh_token;
    const sessionid = responseGoogle?.data.data.session_id;
    setAuth({ sessionid, accesstoken, refreshtoken });
    const verify = {
      refresh: refreshtoken,
      id: sessionid,
      access: accesstoken,
    };
    localStorage["verify"] = JSON.stringify(verify);

    navigate(from, { replace: true });
  };
  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id:
        "475776082423-42dnvom3q375bmf56r6mbf45tfvrk6n1.apps.googleusercontent.com",
      callback: handlegoogleLogin,
    });
    google.accounts.id.renderButton(
      document.getElementById("loginWithGoogle"),
      {
        theme: "outline",
        size: "large",
        width: "auto",
        height: "auto",
        longitude: true,
        position: "center",
      }
    );
    // google.accounts.id.prompt();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Toaster />
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <div className="position-relative overflow-hidden radial-gradient min-vh-100">
          <div className="position-relative z-index-5">
            <div className="row">
              <div className="col-xl-7 col-xxl-8">
                <Link
                  to="/"
                  className="text-nowrap logo-img d-block px-4 py-9 w-100"
                >
                  <img src={Logo} width="180" alt="logo-dark" />
                </Link>
                <div
                  className="d-none d-xl-flex align-items-center justify-content-center"
                  style={{ height: "calc(100vh - 80px)" }}
                >
                  <img
                    src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/backgrounds/login-security.svg"
                    alt="login-white"
                    className="img-fluid"
                    width="500"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-xxl-4">
                <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
                  <div className="col-sm-8 col-md-6 col-xl-9">
                    <h2 className="mb-3 fs-7 fw-bolder text-center">
                      Welcome to Furaha SMS
                    </h2>
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="text-google mx-5"
                        id="loginWithGoogle"
                      ></div>
                    </div>
                    <div className="position-relative text-center my-4">
                      <p className="mb-0 fs-4 px-3 d-inline-block bg-white text-dark z-index-5 position-relative">
                        or sign in with
                      </p>
                      <span className="border-top w-100 position-absolute top-50 start-50 translate-middle"></span>
                    </div>
                    <form
                      autoComplete="off"
                      onSubmit={handleSubmit(onLoginAction)}
                    >
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label">
                          Username / Email Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          aria-describedby="Email Address / Username"
                          autoComplete="off"
                          placeholder="Email Address / Username"
                          autoFocus={true}
                          {...register("username", {
                            required: true,
                            pattern: { value: /^\S+@\S+$/i },
                          })}
                        />
                        {errors?.username?.type === "required" && (
                          <p className="text-small  m-0 text-danger help-block">
                            Please provide a valid username
                          </p>
                        )}
                        {errors?.username?.type === "pattern" && (
                          <p className="text-small  m-0 text-danger help-block">
                            Please provide a valid username
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          autoComplete="off"
                          placeholder="**********************"
                          autoFocus={true}
                          {...register("password", { required: true })}
                        />
                        {errors?.password?.type === "required" && (
                          <p className="text-small m-0 text-danger help-block">
                            Please provide a password
                          </p>
                        )}
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <div className="form-check">
                          <input
                            className="form-check-input primary"
                            type="checkbox"
                            onChange={onRemember}
                            id="flexCheckChecked"
                            defaultChecked={remeber}
                          />
                          <label
                            className="form-check-label text-dark"
                            htmlFor="flexCheckChecked"
                          >
                            Remember this Device
                          </label>
                        </div>
                        <Link
                          className="text-primary fw-medium"
                          to="/recover/email"
                        >
                          Forgot Password ?
                        </Link>
                      </div>
                      <button
                        disabled={disabled}
                        className="btn btn-primary w-100 py-8 mb-4 rounded-2"
                      >
                        {!disabled && "Sign In"}{" "}
                        {disabled && "Please Wait ....."}
                      </button>
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="fs-0 mb-0">New to Furaha SMS ?</p>
                        <Link
                          className="text-primary fw-medium ms-2"
                          to="/register"
                        >
                          Create an account
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginUser;
