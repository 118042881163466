import { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";

const BillingTable = ({ data, isLoading }) => {
  const columns = useMemo(
    () => [
      {
        id: "contacts",
        header: "Contacts",
        size: 50,
        accessorFn: (row) => row.contact,
      },
      {
        id: "type", //normal accessorKey
        header: "Route",
        size: 50,
        accessorFn: (row) => row.type,
      },
      {
        id: "status",
        header: "Status",
        size: 50,
        accessorFn: (row) =>
          row.status === "pending" ? (
            <span
              className="badge bg-info text-sm"
              style={{ fontSize: "11px" }}
            >
              Pending
            </span>
          ) : row.status === "success" ? (
            <span
              className="badge bg-success text-sm"
              style={{ fontSize: "11px" }}
            >
              SuccessFul
            </span>
          ) : (
            <span className="badge bg- text-sm" style={{ fontSize: "11px" }}>
              Failed
            </span>
          ),
      },
      {
        accessorKey: "amount",
        header: "Amount",
        size: 50,
      },
      {
        accessorKey: "timestamp",
        header: "Date",
        size: 150,
      },    
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        className="table align-items-center p-0"
        enableColumnFilterModes
        enableColumnOrdering
        enableRowSelection
        enableGrouping
        enableStickyHeader
        state={{ isLoading: isLoading }}
        muiTableContainerProps={{ sx: { maxHeight: "400px" } }}
        initialState={{ density: "compact" }}
        muiTableHeadCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            fontWeight: "normal",
            fontSize: "12px",
            fontFamily: "Nunito",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "12px",
            fontFamily: "Nunito",
          },
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search",
          sx: { fontFamily: "Nunito", fontSize: "12px" },
        }}
      />
    </>
  );
};

export default BillingTable;
