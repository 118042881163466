import Navbar from "pages/components/navbar";
import Sidebar from "pages/components/sidebar";
import { Link } from "react-router-dom";
import SendNumberSMSCustomised from "./components/customised-bulk";
import { useState } from "react";
const CustomisedNumber = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
  };
  return (
    <>
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          handleSidebarToggle={handleSidebarToggle}
        />

        <div className="body-wrapper">
          <Navbar handleSidebarToggle={handleSidebarToggle} />

          <div className="container-fluid note-has-grid">
            <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3">
                <div className="row align-items-center">
                  <div className="col-9">
                    <h5 className="fw-semibold mb-8">
                      Send Customised Number SMS
                    </h5>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Number-Route-sms
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="col-3">
                    <div className="text-center mb-n5">
                      <img
                        src="assets/images/breadcrumb/ChatBc.png"
                        alt=""
                        className="img-fluid mb-n4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ul className="nav nav-pills p-3 mb-3 rounded-0 align-items-center card flex-row">
              <li className="nav-item">
                <Link
                  to="/number/bulk-sms"
                  className="
                      nav-link
                      note-link
                      d-flex
                      align-items-center
                      justify-content-center
                      
                      px-3 px-md-3
                      me-0 me-md-2 text-body-color
                    "
                  id="all-category"
                >
                  <i className="ti ti-mail fill-white me-0 me-md-1 px-1"></i>
                  <span className="text-medium">Bulk SMS</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/number/group-sms"
                  className="
                      nav-link
                      note-link
                      d-flex
                      align-items-center
                      justify-content-center
                      px-3 px-md-3
                      me-0 me-md-2 text-body-color
                    "
                  id="note-business"
                >
                  <i className="ti ti-relation-many-to-many fill-white me-0 me-md-1 px-1"></i>
                  <span className="text-medium">Group SMS</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/number/custom-sms"
                  className="
                      nav-link
                      active
                      note-link
                      d-flex
                      align-items-center
                      justify-content-center
                      px-3 px-md-3
                      me-0 me-md-2 text-body-color
                    "
                  id="note-social"
                >
                  <i className="ti ti-viewport-narrow fill-white me-0 me-md-1 px-1"></i>
                  <span className="text-medium">Custom SMS</span>
                </Link>
              </li>
            </ul>
            <div className="tab-content">
              <SendNumberSMSCustomised />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomisedNumber;
