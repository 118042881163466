import { useState } from "react";
import ConfirmModal from "pages/components/confirmModal";
import Loader from "services/loaders/loader";

const ApiKeysTable = ({ data, isLoading, getData }) => {
  const [show, setShow] = useState(false);

  const handleConfirm = async () => {
    console.log("");
  };

  const handleCancel = () => {
    console.log("");
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="row">
          {data?.map((data) => (
            <div className="col-md-3 col-sm-12" key={data?.id}>
              <div className="card hover-img shadow-sm">
                <div className="card-body p-2 text-center border-bottom">
                  <p className="mb-0 fs-1">Username:</p>
                  <code className="text-dark fs-1">{data?.username}</code>
                  <p className="mb-0 fs-1">ApiKey:</p>
                  <code className="text-dark fs-1">{data?.code}</code>
                </div>
                <div className="container btn-group p-2">
                  <button className="btn btn-sm btn-success">Summary</button>
                  <button className="btn btn-sm btn-info">Suspend</button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => setShow(true)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <ConfirmModal
        message="Are you sure you want to proceed to delete this key?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default ApiKeysTable;
