import Navbar from "pages/components/navbar";
import Sidebar from "pages/components/sidebar";
import ContactGroupImage from "pages/assets/images/team.png";
import SentSMSImage from "pages/assets/images/smartphone.png";
import PlatformImage from "pages/assets/images/online-lesson.png";
import ApiImage from "pages/assets/images/api.png";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "services/loaders/loader";
import { useEffect, useState } from "react";
// import useTimeAgo from "middlewares/hooks/useTimeAgo";
const Dashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoadingContacts, setIsLoadingContacts] = useState(true);
  const [isLoadingOutbox, setIsLoadingOutbox] = useState(true);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [isLoadingPayments, setIsLoadingPayments] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [outbox, setOutbox] = useState([]);
  const [profile, setProfile] = useState([]);
  const [recentPayments, setRecentPayments] = useState([]);
  const controller = new AbortController();

  const getContacts = async () => {
    try {
      const response = await axiosPrivate.get("/contacts/groups", {
        signal: controller.signal,
      });
      setContacts(response.data.data.rows_returned);
      setIsLoadingContacts(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const getSmsSent = async () => {
    try {
      const response = await axiosPrivate.get("/outbox/general", {
        signal: controller.signal,
      });
      setOutbox(response.data.data);
      setIsLoadingOutbox(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const getProfile = async () => {
    try {
      const response = await axiosPrivate.get("/accounts/profile", {
        signal: controller.signal,
      });
      setProfile(response.data.data.user);
      setIsLoadingProfile(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  const getRecentPayments = async () => {
    try {
      const response = await axiosPrivate.get("/billing/general", {
        signal: controller.signal,
      });
      setRecentPayments(response.data.data.transactions);
      setIsLoadingPayments(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getContacts();
    getSmsSent();
    getProfile();
    getRecentPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

//   const dates = recentPayments.map((item) => new Date(item.timestamp));

//   const timeAgoArray = useTimeAgo(dates);

//   const dates_outbox = outbox?.sms?.slice(0,5).map((item) => new Date(item.timestamp));

//   const timeAgoOutboxArray = useTimeAgo(dates_outbox);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
  };
  return (
    <>
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-theme="blue_theme"
        data-layout="vertical"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          handleSidebarToggle={handleSidebarToggle}
        />

        <div className="body-wrapper">
          <Navbar handleSidebarToggle={handleSidebarToggle} />
          <div className="container-fluid">
            <div className="row  mt-md-0 mt-5">
              <div className="col-md-3 col-sm-12">
                <div className="card border-0 zoom-in bg-light-primary shadow-none">
                  <div className="card-body">
                    <div className="text-center">
                      <img
                        src={ContactGroupImage}
                        width="50"
                        height="50"
                        className="mb-3"
                        alt="male"
                      />
                      <p className="fw-semibold fs-3 text-primary mb-1">
                        {" "}
                        Contact Groups{" "}
                      </p>
                      {isLoadingContacts ? (
                        <Loader />
                      ) : (
                        <h5 className="fw-semibold text-primary mb-0">
                          {contacts}
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="card border-0 zoom-in bg-light-warning shadow-none">
                  <div className="card-body">
                    <div className="text-center">
                      <img
                        src={SentSMSImage}
                        width="50"
                        height="50"
                        className="mb-3"
                        alt=""
                      />
                      <p className="fw-semibold fs-3 text-warning mb-1">
                        Outbox
                      </p>
                      {isLoadingOutbox ? (
                        <Loader />
                      ) : (
                        <h5 className="fw-semibold text-warning mb-0">
                          {outbox?.rows_returned}
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="card border-0 zoom-in bg-light-info shadow-none">
                  <div className="card-body">
                    <div className="text-center">
                      <img
                        src={PlatformImage}
                        width="50"
                        height="50"
                        className="mb-3"
                        alt=""
                      />
                      <p className="fw-semibold fs-3 text-info mb-1">
                        Platform SMS
                      </p>
                      {isLoadingOutbox ? (
                        <Loader />
                      ) : (
                        <h5 className="fw-semibold text-warning mb-0">
                          {
                            outbox?.sms?.filter((sms) => sms.route === "basic")
                              ?.length
                          }
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="card border-0 zoom-in bg-light-danger shadow-none">
                  <div className="card-body">
                    <div className="text-center">
                      <img
                        src={ApiImage}
                        width="50"
                        height="50"
                        className="mb-3"
                        alt=""
                      />
                      <p className="fw-semibold fs-3 text-danger mb-1">
                        Api SMS
                      </p>
                      {isLoadingOutbox ? (
                        <Loader />
                      ) : (
                        <h5 className="fw-semibold text-warning mb-0">
                          {
                            outbox?.sms?.filter((sms) => sms.route === "api")
                              ?.length
                          }
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card w-100 bg-light-info overflow-hidden shadow-none">
                  <div className="card-body position-relative">
                    <div className="row">
                      <div className="col-sm-7">
                        <div className="d-flex align-items-center mb-7">
                          <div className="rounded-circle overflow-hidden me-6">
                            <img
                              src="assets/images/profile/user-1.jpg"
                              alt=""
                              width="40"
                              height="40"
                            />
                          </div>
                          <h5 className="fw-semibold mb-0 fs-5">
                            Hello,{" "}
                            {isLoadingProfile ? (
                              <Loader />
                            ) : (
                              `${profile?.firstname} ${profile?.lastname} !`
                            )}{" "}
                          </h5>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="border-end pe-4 border-muted border-opacity-10">
                            <h3 className="mb-1 fw-semibold fs-8 d-flex align-content-center">
                              UGX{" "}
                              {isLoadingProfile ? (
                                <Loader />
                              ) : (
                                profile?.balance?.toLocaleString()
                              )}
                              <i className="ti ti-arrow-up-right fs-5 lh-base text-success"></i>
                            </h3>
                            <p className="mb-0 text-dark">Account Balance</p>
                          </div>
                          <div className="ps-4">
                            <h3 className="mb-1 fw-semibold fs-8 d-flex align-content-center">
                              UGX 0
                              <i className="ti ti-arrow-up-right fs-5 lh-base text-success"></i>
                            </h3>
                            <p className="mb-0 text-dark">Earned Bonus</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-5">
                        <div className="welcome-bg-img mb-n7 text-end">
                          <img
                            src="assets/images/welcome-bg.svg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="mb-4">
                      <h5 className="card-title fw-semibold">
                        Recent Transactions
                      </h5>
                      <p className="card-subtitle">Payment Transactions</p>
                    </div>
                    <ul className="timeline-widget mb-0 position-relative mb-n5">
                      {isLoadingPayments ? (
                        <Loader />
                      ) : (
                        recentPayments?.slice(0, 5)?.map((payment, index) => (
                          <li
                            className="timeline-item d-flex position-relative overflow-hidden"
                            key={payment.id}
                          >
                            {/* <div className="timeline-time text-dark flex-shrink-0 text-end">
                              {timeAgoArray[index]}
                            </div> */}
                            <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                              <span className="timeline-badge border-2 border border-primary flex-shrink-0 my-8"></span>
                              <span className="timeline-badge-border d-block flex-shrink-0"></span>
                            </div>
                            <div className="timeline-desc fs-3 text-dark mt-n1">
                              Payment of ugx {payment?.amount?.toLocaleString()}{" "}
                              using {payment?.contact}
                            </div>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8 d-flex align-items-stretch">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="d-sm-flex d-block align-items-center justify-content-between mb-3">
                      <div className="mb-3 mb-sm-0">
                        <h5 className="card-title fw-semibold">
                          Recent Messages
                        </h5>
                        <p className="card-subtitle">Recent sent Messages?</p>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {isLoadingOutbox ? (
                        <Loader />
                      ) : (
                        <table className="table align-middle text-nowrap mb-0">
                          <thead>
                            <tr className="text-muted fw-semibold">
                              {/* <th scope="col" className="ps-0">
                                Message
                              </th> */}
                              <th scope="col">Contacts</th>
                              <th scope="col">Route</th>
                              <th scope="col">Type</th>
                              <th scope="col">Time</th>
                            </tr>
                          </thead>

                          <tbody className="border-top">
                            {outbox?.sms?.slice(0, 5)?.map((item, index) => (
                              <tr key={item?.id}>
                                {/* <td className="ps-0">
                                  <p className="fs-2 mb-0 text-muted">
                                    {item?.message}
                                  </p>
                                </td> */}
                                <td>
                                  <p className="mb-0 fs-3 text-dark">
                                    {item?.contacts?.length}
                                  </p>
                                </td>
                                <td>
                                  {item?.route === "basic" ? (
                                    <span className="badge text-small py-1 bg-primary text-white">
                                      Platform
                                    </span>
                                  ) : (
                                    <span className="badge text-small py-1 bg-danger text-white">
                                      API
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <p className="fs-3 text-dark mb-0">
                                    {item?.type}
                                  </p>
                                </td>
                                <td>{item?.timestamp}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobilenavbar */}
    </>
  );
};

export default Dashboard;
