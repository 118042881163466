import { useState } from "react";
import ConfirmModal from "pages/components/confirmModal";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
import GroupImage from "pages/assets/images/customer_two.png";
import Loader from "services/loaders/loader";
import ShowContacts from "./showContacts";
import UpdateContactsModal from "./updateContactsModal";
import SendContactsModal from "./sendContactsModal";
const ContactsComponents = ({ data, isLoading, getData }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#020f46" });
  const [updateInfo, setUpdateInfo] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showContactsModal, setShowContactsModal] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState([]);
  const handleCloseModal = () => {
    setShowModal(false);
    setUpdateInfo([]);
  };
  const handleOpenModal = (info) => {
    setShowModal(true);
    setUpdateInfo(info);
  };
    const handleCloseMessageModal = () => {
      setShowMessageModal(false);
      setUpdateInfo([]);
    };
    const handleOpenMessageModal = (info) => {
      setShowMessageModal(true);
      setUpdateInfo(info);
    };
  const handleContactsCloseModal = () => {
    setShowContactsModal(false);
    setContacts([]);
  };
  const handleContactsOpenModal = (contacts) => {
    setShowContactsModal(true);
    setContacts(contacts);
  };

  const handleConfirm = async () => {
    try {
      progress.start();
    
      const controller = new AbortController();
      const response = await axiosPrivate.delete(`/contacts/groups/${id}`, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      getData();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        console.log(error?.response);
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const handleCancel = () => {
    setContacts([]);
  };

  return (
    <>
      <Toaster />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="row">
          {data?.map((group) => (
            <div className="col-md-4 col-sm-12" key={group?.id}>
              <div className="card hover-img shadow-sm">
                <div className="card-body p-2 text-center border-bottom">
                  <img
                    src={GroupImage}
                    alt="GroupImage"
                    className="mb-3"
                    width="50"
                    height="50"
                  />
                  <h5 className="fw-semibold mb-0 fs-3">{group?.title}</h5>
                  <span className="text-dark fs-2">
                    {group?.contacts?.length} Contacts
                  </span>
                </div>
                <div className="container btn-group p-2">
                  <button
                    className="btn btn-sm btn-warning"
                    onClick={() => handleContactsOpenModal(group?.contacts)}
                  >
                    View
                  </button>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => handleOpenModal(group)}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => handleOpenMessageModal(group)}
                  >
                    Send Message
                  </button>
                  <button className="btn btn-sm btn-danger" onClick={()=>{setId(group.id); setShow(true); }}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <ShowContacts
        showContactsModal={showContactsModal}
        handleContactsCloseModal={handleContactsCloseModal}
        contacts={contacts}
      />
      <ConfirmModal
        message="Are you sure you want to proceed to Resend This Message?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
      <UpdateContactsModal
        handleCloseModal={handleCloseModal}
        showModal={showModal}
        getData={getData}
        groupname={updateInfo?.title}
        groupid={updateInfo?.id}
      />
      <SendContactsModal
        handleCloseModal={handleCloseMessageModal}
        showModal={showMessageModal}
        getData={getData}
        groupname={updateInfo?.title}
        groupid={updateInfo?.id}
      />
    </>
  );
};

export default ContactsComponents;
