import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
import Papa from "papaparse";

const SendInfoSMSCustomised = () => {
  const [text, setText] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const charactersPerMessage = 160;
  const textvalueRef = useRef(null);
  const formRef = useRef(null);
  const controller = new AbortController();

  const [messageError, setMessageError] = useState(false);
  const handleChange = (event) => {
    const { value } = event.target;
    setText(value);
  };

  useEffect(() => {
    const messages = Math.ceil(text.length / charactersPerMessage);
    setMessageCount(messages);
  }, [text]);

  const remainingCharacters = text.length % charactersPerMessage;

  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#020f46" });
   const [smsText, setSmsText] = useState("");

  const onSave = async (event) => {
    event.preventDefault();
    
    if (text.length === 0) {
      setMessageError(true);
      textvalueRef.current.focus();
      return;
    }

    try {
      progress.start();
      setDisabled(true);
      const newData = {
        message: smsText,
      };
      console.log(newData);
      const response = await axiosPrivate.post("/sms/end/info", newData, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      formRef.current.reset();
      setText("");
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
   const [csvData, setCsvData] = useState([]);
   const [selectedValue, setSelectedValue] = useState("");
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      Papa.parse(file, {
        complete: (result) => {
          if (result.data && result.data.length > 0) {
            setCsvData(result.data);
            setSelectedValue(result.data[0][0]); // Initialize with the first value
          }
        },
      });
    };

    const handleSelectChange = (e) => {
      setSelectedValue(e.target.value);
      setSmsText(""); // Reset SMS text when the selected value changes
    };

  return (
    <>
      <Toaster />
      <div className="card">
        <div className="card-body">
          <h5 className="mb-3">Send Custom Info SMS</h5>

          <form ref={formRef} onSubmit={onSave}>
            <div className="row">
              <div className="col-md-7 col-sm-12">
                <label htmlFor="formFile" className="form-label">
                  Choose CSV File
                </label>
                <input
                  className="form-control"
                  accept=".csv"
                  type="file"
                  id="formFile"
                  onChange={handleFileChange}
                />
              </div>
              <div className="col-md-5 col-sm-12">
                <p className="text-medium">
                  {" "}
                  <span className="text-bold mt-5">Upload Csv File</span>
                </p>
              </div>
              <div className="col-md-7 col-sm-12">
                <label className="form-label p-2" htmlFor="formSelect">
                  Default Contacts Column
                </label>
                <select
                  className="form-control form-select mb-2 pb-3"
                  data-placeholder="Choose a Default Phone Column"
                  tabindex="1"
                  id="formSelect"
                  value={selectedValue}
                  onChange={handleSelectChange}
                >
                  {csvData[0]?.map((header, index) => (
                    <option key={index} value={header}>
                      {header}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-5 col-sm-12">
                <p className="text-medium">
                  Download The Sample CSV FIle <br />
                  <span className="text-bold">
                    <a href="samples/sample.csv" download>
                      {" "}
                      Click Here To Download{" "}
                    </a>
                  </span>
                </p>
              </div>
              <div className="col-md-7 col-sm-12">
                <div className="form-floating mb-3">
                  <textarea
                    type="text"
                    className="form-control"
                    id="message"
                    value={text}
                    onChange={handleChange}
                    style={{
                      height: "200px",
                      border:
                        messageError && text.length === 0
                          ? "2px solid red"
                          : "1px solid #ccc",
                    }}
                    ref={textvalueRef}
                    onFocus={() => {
                      // Change the textarea background color when focused
                      textvalueRef.current.style.backgroundColor = "#fcf8f7";
                    }}
                    onBlur={() => {
                      // Reset the textarea background color when focus is lost
                      textvalueRef.current.style.backgroundColor = "white";
                    }}
                  ></textarea>
                  <label htmlFor="message">Message Body</label>
                  {messageError && text.length === 0 && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Message field can not be empty
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-5 col-sm-12">
                <p className="text-medium">
                  Characters remaining: {remainingCharacters}/
                  {charactersPerMessage}
                </p>
                <p>Number of messages: {messageCount}</p>
              </div>

              <div className="float-end mt-3 mt-md-0">
                <button
                  type="submit"
                  className="btn btn-info font-medium rounded-0 px-4"
                  disabled={disabled}
                >
                  <div className="d-flex align-items-center">
                    <i className="ti ti-send me-2 fs-4"></i>
                    {!disabled && "Send SMS"}{" "}
                    {disabled && "Sending Please Wait ....."}
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SendInfoSMSCustomised;
