import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
import { useState, useEffect, useRef } from "react";
const SendNumberSMS = () => {
  const [text, setText] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const charactersPerMessage = 160;
  const textareaRef = useRef(null);
  const textvalueRef = useRef(null);
  const datevalueRef = useRef(null);
  const formRef = useRef(null);
  const [contactsError, setContactsError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const handleChange = (event) => {
    const { value } = event.target;
    setText(value);
  };

  useEffect(() => {
    const messages = Math.ceil(text.length / charactersPerMessage);
    setMessageCount(messages);
  }, [text]);

  const remainingCharacters = text.length % charactersPerMessage;
  const [contacts, setContacts] = useState([]);

  const handleChangeContacts = (event) => {
    const value = event.target.value;
    const newContacts = value
      .split(/\s*[,|\n]\s*/)
      .filter((contact) => contact.trim() !== "");
    setContacts(newContacts);
  };
  // console.log(contacts)
  const [dateTimeValue, setDateTimeValue] = useState('');
  const handleDateTimeChange = (event) => {
    setDateTimeValue(event.target.value);
  };
    const [showExtraField, setShowExtraField] = useState(false);

    const handleCheckboxChange = () => {
      setShowExtraField(!showExtraField);
    };
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#020f46" });

  const onSave = async (event) => {
    event.preventDefault();
    if (text.length === 0) {
      setMessageError(true);
      textvalueRef.current.focus();
      return;
    }
    if (contacts.length === 0) {
      setContactsError(true);
      textareaRef.current.focus();
      return;
    }
    if (showExtraField === false){
      try {
        // console.log("info");
        progress.start();
        setDisabled(true);
        const newData = {
          message: text,
          recipients: contacts,
          type: "number",
        };
        const controller = new AbortController();
        const response = await axiosPrivate.post("/sms/send/info", newData, {
          signal: controller.signal,
        });
        toast.success(response?.data?.messages);
        progress.finish();
        setDisabled(false);
        formRef.current.reset();
        setText("");
        setContacts([]);
      } catch (error) {
        if (!error?.response) {
          toast.error("No server response");
          progress.finish();
          setDisabled(false);
        } else {
          console.log(error?.response);
          progress.finish();
          setDisabled(false);
          if (error?.response?.status === 401) {
            navigate("/", { state: { from: location }, replace: true });
          }
          toast.error(error?.response?.data?.messages, {
            style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
          });
        }
      }
    }else {
      if (dateTimeValue.length === 0) {
      setDateError(true);
      datevalueRef.current.focus();
      return;
    }
    console.log(dateTimeValue)
       try {
         progress.start();
         setDisabled(true);
         const newData = {
           message: text,
           recipients: contacts,
           type: "number",
           datetime: dateTimeValue,
         };
         const controller = new AbortController();
         const response = await axiosPrivate.post("/sms/schedule", newData, {
           signal: controller.signal,
         });
         toast.success(response?.data?.messages);
         progress.finish();
         setDisabled(false);
         formRef.current.reset();
         setText("");
         setContacts([]);
       } catch (error) {
         if (!error?.response) {
           toast.error("No server response");
           progress.finish();
           setDisabled(false);
         } else {
           console.log(error?.response);
           progress.finish();
           setDisabled(false);
           if (error?.response?.status === 401) {
             navigate("/", { state: { from: location }, replace: true });
           }
           toast.error(error?.response?.data?.messages, {
             style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
           });
         }
       }
    }
  };


  return (
    <>
      <Toaster />
      <div className="card">
        <div className="card-body">
          <h5 className="mb-3">Send Number Route SMS</h5>
          <form ref={formRef} onSubmit={onSave}>
            <div className="row">
              <div className="col-md-7 col-sm-12">
                <div className="form-floating mb-3">
                  <textarea
                    type="text"
                    className="form-control"
                    id="message"
                    value={text}
                    onChange={handleChange}
                    style={{
                      height: "200px",
                      border:
                        messageError && text.length === 0
                          ? "2px solid red"
                          : "1px solid #ccc",
                    }}
                    ref={textvalueRef}
                    onFocus={() => {
                      // Change the textarea background color when focused
                      textvalueRef.current.style.backgroundColor = "#fcf8f7";
                    }}
                    onBlur={() => {
                      // Reset the textarea background color when focus is lost
                      textvalueRef.current.style.backgroundColor = "white";
                    }}
                  ></textarea>
                  <label htmlFor="message">Message Body</label>
                  {messageError && text.length === 0 && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Message field can not be empty
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-5 col-sm-12">
                <p className="text-medium">
                  Characters remaining: {remainingCharacters}/
                  {charactersPerMessage}
                </p>
                <p>Number of messages: {messageCount}</p>
              </div>
              <div className="col-md-7 col-sm-12">
                <div className="form-floating mb-3">
                  <textarea
                    type="text"
                    className="form-control"
                    id="contacts"
                    onChange={handleChangeContacts}
                    style={{
                      height: "200px",
                      border:
                        contactsError && contacts.length === 0
                          ? "2px solid red"
                          : "1px solid #ccc",
                    }}
                    ref={textareaRef}
                    onFocus={() => {
                      // Change the textarea background color when focused
                      textareaRef.current.style.backgroundColor = "#fcf8f7";
                    }}
                    onBlur={() => {
                      // Reset the textarea background color when focus is lost
                      textareaRef.current.style.backgroundColor = "white";
                    }}
                  ></textarea>
                  <label htmlFor="contacts">Recipient Contacts</label>
                  {contactsError && contacts.length === 0 && (
                    <p className="text-small m-0 px-1 text-start text-danger help-block">
                      Contacts field can not be empty
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-5 col-sm-12">
                <p className="text-medium">
                  Number of Contacts: {contacts.length}
                </p>
                <p className="text-medium">
                  E.g. 0766xxxxxx 256755xxxxxx <br /> Or use a comma between
                  contacts <br />
                  e.g. 0772xxxxxx, 256755xxxxxx
                </p>
              </div>
              <div className="col-md-7 col-sm-12">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input primary"
                    checked={showExtraField}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                    id="success2-check"
                    value="scheduled"
                  />
                  <label className="form-check-label" htmlFor="success2-check">
                    Click To Schedule SMS
                  </label>
                </div>
                {showExtraField && (
                  <div className="form-group pt-3 pb-3">
                    <input
                      type="datetime-local"
                      className="form-control"
                      onChange={handleDateTimeChange}
                      placeholder="Select date and time"
                      ref={datevalueRef}
                      onFocus={() => {
                        // Change the textarea background color when focused
                        datevalueRef.current.style.backgroundColor = "#fcf8f7";
                      }}
                      onBlur={() => {
                        // Reset the textarea background color when focus is lost
                        datevalueRef.current.style.backgroundColor = "white";
                      }}
                    />
                    {dateError && dateTimeValue.length === 0 && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Date field can not be empty
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-5 col-sm-12">
                <p className="text-medium">
                  Click To Schedule SMS to send later.
                </p>
              </div>
              <div className="float-end mt-3 mt-md-0">
                <button
                  type="submit"
                  className="btn btn-info font-medium rounded-0 px-4"
                  disabled={disabled}
                >
                  <div className="d-flex align-items-center">
                    <i className="ti ti-send me-2 fs-4"></i>
                    {!disabled && "Send SMS"}{" "}
                    {disabled && "Sending Please Wait ....."}
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SendNumberSMS;
