import Logo from "pages/assets/logos/logo-dark.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../config/axios";
import toast, { Toaster } from "react-hot-toast";
import useAuth from "middlewares/hooks/useAuth";
const Register = () => {
  const [disabled, setDisabled] = useState(false);
  const nagivate = useNavigate();
  const { setAuth } = useAuth();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setDisabled(true);
      const response = await axios.post("/register/new", data);
      const token = response?.data.data.token.token;
      setAuth({ token });
      nagivate("/register/verify");
    } catch (error) {
      if (!error?.response) {
        toast("No server response");
        setDisabled(false);
      } else {
        setDisabled(false);
        toast(error?.response?.data?.messages);
      }
    }
  }; 
  return (
    <>
    <Toaster />
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <div className="position-relative overflow-hidden radial-gradient min-vh-100">
          <div className="position-relative z-index-5">
            <div className="row">
              <div className="col-xl-7 col-xxl-8">
                <Link
                  to="/"
                  className="text-nowrap logo-img d-block px-4 py-9 w-100"
                >
                  <img src={Logo} width="180" alt="logo" />
                </Link>
                <div
                  className="d-none d-xl-flex align-items-center justify-content-center"
                  style={{ height: "calc(100vh - 80px)" }}
                >
                  <img
                    src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/backgrounds/login-security.svg"
                    alt=""
                    className="img-fluid"
                    width="500"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-xxl-4">
                <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
                  <div className="col-sm-8 col-md-6 col-xl-9">
                    <h4 className="mb-3 fs-7 fw-bolder">Create new account</h4>
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                      <div className="mb-3 row">
                        <div className="col-12">
                          <label htmlFor="firstname" className="form-label">
                            FirstName
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstname"
                            aria-describedby="Firstname"
                            placeholder="Firstname *"
                            autoComplete="off"
                            autoFocus={true}
                            {...register("firstname", { required: true })}
                          />
                          {errors?.firstname?.type === "required" && (
                            <p className="text-small text-center text-danger help-block">
                              Please enter your firstname
                            </p>
                          )}
                        </div>
                        <div className="col-12">
                          <label htmlFor="lastname" className="form-label">
                            LastName
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastname"
                            aria-describedby="Lastname"
                            placeholder="Lastname *"
                            autoComplete="off"
                            autoFocus={true}
                            {...register("lastname", { required: true })}
                          />
                          {errors?.lastname?.type === "required" && (
                            <p className="text-small text-center text-danger help-block">
                              Please enter your lastname
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-12">
                          <label htmlFor="email" className="form-label">
                            Email address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            aria-describedby="Email"
                            placeholder="Email *"
                            autoComplete="off"
                            autoFocus={true}
                            {...register("email", {
                              required: true,
                              pattern: { value: /^\S+@\S+$/i },
                            })}
                          />
                          {errors?.email?.type === "required" && (
                            <p className="text-small  m-0 text-danger help-block">
                              Please provide a valid Email Address
                            </p>
                          )}
                          {errors?.email?.type === "pattern" && (
                            <p className="text-small  m-0 text-danger help-block">
                              Please provide a valid Email Address
                            </p>
                          )}
                        </div>
                        <div className="col-12">
                          <label htmlFor="contact" className="form-label">
                            Phone Number
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            id="contact"
                            aria-describedby="Contact"
                            placeholder="Contact *"
                            autoComplete="off"
                            autoFocus={true}
                            {...register("contact", { required: true })}
                          />
                          {errors?.contact?.type === "required" && (
                            <p className="text-small text-center text-danger help-block">
                              Please enter your contact
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mb-4 row">
                        <div className="col-12">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Password * "
                            autoFocus={true}
                            {...register("password", { required: true })}
                          />
                          {errors?.password?.type === "required" && (
                            <p className="text-small text-center text-danger help-block">
                              Please provide a password
                            </p>
                          )}
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="password_confirmation"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="password_confirmation"
                            placeholder="Confirm Password * "
                            {...register("password_confirmation", {
                              required: true,
                              validate: (value) => {
                                return value === watch("password") || false;
                              },
                            })}
                          />
                          {watch("password_confirmation") !==
                            watch("password") &&
                          getValues("password_confirmation") ? (
                            <p className="text-small text-center text-danger help-block">
                              Password not match
                            </p>
                          ) : null}
                          {errors?.password_confirmation?.type ===
                            "required" && (
                            <p className="text-small text-center text-danger help-block">
                              Please confirm password
                            </p>
                          )}
                        </div>
                      </div>
                      <button
                        disabled={disabled}
                        className="btn btn-primary w-100 py-8 mb-4 rounded-2"
                      >
                        {!disabled && "Sign up"}{" "}
                        {disabled && "Please Wait ....."}
                      </button>
                      <div className="d-flex align-items-center">
                        <p className="fs-0 mb-0 text-dark">
                          Already have an Account?
                        </p>
                        <Link className="text-primary fw-medium ms-2" to="/">
                          Sign In
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
