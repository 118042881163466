import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";

const NewPayment = ({ showModal, handleCloseModal }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "blue" });
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSave = async (data) => {try {
    console.log(data)
    progress.start();
    setDisabled(true);
    const controller = new AbortController();
    const response = await axiosPrivate.post(
      "/billing/payments/mobilemoney",
      data,
      {
        signal: controller.signal,
      }
    );
    // console.log(response?.data)
    toast.success(response?.data?.messages);
    progress.finish();
    handleCloseModal();
    setDisabled(false);
    reset();    
  } catch (error) {
    if (!error?.response) {
      toast.error("No server response");
      progress.finish();
      setDisabled(false);
    } else {
      console.log(error?.response);
      progress.finish();
      setDisabled(false);
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
      toast.error(error?.response?.data?.messages, {
        style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
      });
    }
  }};
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title className="text-center"> Make Payment </Modal.Title>
          <button onClick={handleCloseModal} className="btn-close btn-danger">
            <i className="fa fa-close"></i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body className="container row">
            <div className="form-group col-md-12">
              <label htmlFor="contact" className="form-label p-2">
                Phone Number (MTN / AIRTEL)
              </label>
              <input
                type="tel"
                name="contact"
                placeholder="Format: 2567..... or 07...."
                className="form-control py-3"
                {...register("contact", {
                  required: true,
                })}
              />
              {errors?.contact?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter a valid contact
                </p>
              )}
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="amount" className="form-label p-2">
                Amount
              </label>
              <input
                type="number"
                name="amount"
                placeholder="Enter prefered amount *"
                className="form-control py-3"
                {...register("amount", {
                  required: true,
                })}
              />
              {errors?.amount?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter amount
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="Button" variant="danger" onClick={handleCloseModal}>
              Close
            </Button>
            <Button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Buy"} {disabled && "Please Wait"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default NewPayment;
