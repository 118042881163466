import Loader from "services/loaders/loader";
import { format } from "date-fns";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const Profile = ({ data, isLoading, getData }) => {
  const progress = new ProgressBar({ size: 5, color: "blue" });
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      // console.log("info");
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/accounts/password/update",
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="card hover-img shadow-sm">
              <div className="card-body p-2 border-bottom">
                <div className="h6">Profile Information</div>
                <hr />
                <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                  <div className="mb-0 fs-2 px-2">Fullname</div>
                  <code className="text-dark text-bold fs-2">{`${data?.firstname} ${data?.lastname}`}</code>
                </div>
                <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                  <div className="mb-0 fs-2 px-2">Contact</div>
                  <code className="text-dark text-bold fs-2">
                    {data?.contact}
                  </code>
                </div>
                <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                  <div className="mb-0 fs-2 px-2">Email</div>
                  <code className="text-dark text-bold fs-2">
                    {data?.email}
                  </code>
                </div>
                <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                  <div className="mb-0 fs-2 px-2">Account Opening Date</div>
                  <code className="text-dark text-bold fs-2">
                    {format(new Date(data?.timestamp), "do MMMM yyyy HH:mm")}
                  </code>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card hover-img shadow-sm">
              <div className="card-body p-2 border-bottom">
                <div className="h6">SMS Plan</div>
                <hr />
                <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                  <div className="mb-0 fs-2 px-2">Number Route</div>
                  <code className="text-dark text-bold fs-2">{`UGX ${data?.plans?.plan_number_route}`}</code>
                </div>
                <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                  <div className="mb-0 fs-2 px-2">Info SMS</div>
                  <code className="text-dark text-bold fs-2">
                    {`UGX ${data?.plans?.plan_info_sms}`}
                  </code>
                </div>
                <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                  <div className="mb-0 fs-2 px-2">International Route</div>
                  <code className="text-dark text-bold fs-2">
                    {`UGX ${data?.plans?.plan_customised}`}
                  </code>
                </div>
                <div className="row-container py-2 d-flex align-items-center justify-content-between bio-info">
                  <div className="mb-0 fs-2 px-2">Account Balance</div>
                  <code className="text-dark text-bold fs-2">
                    {`UGX ${data?.balance?.toLocaleString()}`}
                  </code>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card hover-img shadow-sm">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body p-2 border-bottom">
                  <div className="h6">Update Password</div>
                  <hr />
                  <div className="p-1">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Old Password"
                      {...register("old_password", { required: true })}
                    />
                    {errors?.old_password?.type === "required" && (
                      <p className="text-small text-center text-danger help-block">
                        Please provide old password
                      </p>
                    )}
                  </div>
                  <div className="p-1">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter New Password"
                      {...register("new_password", { required: true })}
                    />
                    {errors?.new_password?.type === "required" && (
                      <p className="text-small text-center text-danger help-block">
                        Please provide a new password
                      </p>
                    )}
                  </div>
                  <div className="p-1">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm New Password"
                      {...register("password_confirmation", {
                        required: true,
                        validate: (value) => {
                          return value === watch("new_password") || false;
                        },
                      })}
                    />
                    {watch("password_confirmation") !== watch("new_password") &&
                    getValues("password_confirmation") ? (
                      <p className="text-small text-center text-danger help-block">
                        Password not match
                      </p>
                    ) : null}
                    {errors?.password_confirmation?.type === "required" && (
                      <p className="text-small text-center text-danger help-block">
                        Please confirm password
                      </p>
                    )}
                  </div>
                </div>
                <div className="container btn-group p-2">
                  <button className="btn btn-sm btn-danger" disabled={disabled}>
                    {!disabled && "Update"} {disabled && "Updating ....."}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
