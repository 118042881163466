import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "layouts/layout";
import Missing from "layouts/404";
import LoginUser from "pages/authentication/login.js";
import RequiredAuth from "middlewares/hooks/requiredLogin";
import PersitLogin from "middlewares/global/persistentLogin";
import Register from "pages/authentication/register";
import ForgotPassword from "pages/authentication/forgot";
import VerificationSignUp from "pages/authentication/verification";
import Dashboard from "pages/dashboard/dashboard";
import ContactsInfo from "pages/info-sms/contacts";
import GroupInfo from "pages/info-sms/group";
import CustomisedInfo from "pages/info-sms/customised";
import ContactsNumber from "pages/number-route-sms/contacts";
import GroupNumber from "pages/number-route-sms/group";
import CustomisedNumber from "pages/number-route-sms/customised";
import ScheduledSMS from "pages/scheduled-sms/scheduledsms";
import Outbox from "pages/oubox/outbox";
import ContactsGroups from "pages/contacts/contacts";
import NewBilling from "pages/billing/billing";
import BillingHistory from "pages/billing/billing-history";
import ApiKeys from "pages/api-keys/api-keys";
import UserProfile from "pages/profile/profile";

function App() {
  return (
    <>
      <Routes>
        <Route path="" element={<Layout />}>
          <Route path="/" element={<LoginUser />} />
          <Route path="/register" element={<Register />} />
          <Route path="/recover/email" element={<ForgotPassword />} />
          <Route path="/register/verify" element={<VerificationSignUp />} />
          <Route element={<PersitLogin />}>
            <Route element={<RequiredAuth />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/info/bulk-sms" element={<ContactsInfo />} />
              <Route path="/info/group-sms" element={<GroupInfo />} />
              <Route path="/info/custom-sms" element={<CustomisedInfo />} />
              <Route path="/number/bulk-sms" element={<ContactsNumber />} />
              <Route path="/number/group-sms" element={<GroupNumber />} />
              <Route path="/number/custom-sms" element={<CustomisedNumber />} />
              <Route path="/scheduled-sms" element={<ScheduledSMS />} />
              <Route path="/manage/outbox" element={<Outbox />} />
              <Route path="/manage/contacts" element={<ContactsGroups />} />
              <Route path="/billing/buy-sms" element={<NewBilling />} />
              <Route path="/billing/history" element={<BillingHistory />} />
              <Route path="/developer/api-keys" element={<ApiKeys />} />
              <Route path="/accounts/profile" element={<UserProfile />} />
            </Route>
          </Route>
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
