import Navbar from "pages/components/navbar";
import Sidebar from "pages/components/sidebar";
import { Link } from "react-router-dom";
import { useState } from "react";

import NewPayment from "./components/payment";
import MobileMoney from "pages/assets/images/MTN-Airtel.png";
import CardPayments from "pages/assets/images/visaclassiccredit-400x225.jpg";
import Bank from "pages/assets/images/other-banks-in-Uganda.jpg";
const NewBilling = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
  };

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          handleSidebarToggle={handleSidebarToggle}
        />
        <div className="body-wrapper">
          <Navbar handleSidebarToggle={handleSidebarToggle} />

          <div className="container-fluid note-has-grid">
            <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3 ">
                <div className="row align-items-center">
                  <div className="col-9">
                    <h5 className="fw-semibold mb-8">Payments</h5>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Payments
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="col-3">
                    <div className="text-center mb-n5">
                      <img
                        src="assets/images/breadcrumb/ChatBc.png"
                        alt=""
                        className="img-fluid mb-n4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild card">
              <div className="card-body row">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body p-4">
                      <div className="d-flex align-items-center justify-content-center mb-6">
                        <h6 className="mb-0 fw-semibold fs-4 mb-0">
                          Mobile Money
                        </h6>
                      </div>
                      <img
                        src={MobileMoney}
                        alt=""
                        className="img-fluid rounded-4 w-100 object-fit-cover mb-4"
                        style={{ height: "150px" }}
                      />
                      <button
                        onClick={handleOpenModal}
                        className="btn btn-primary w-100 fw-normal py-8"
                      >
                        Make Payment
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body p-4">
                      <div className="d-flex align-items-center justify-content-center mb-6">
                        <h6 className="mb-0 fw-semibold fs-4 mb-0">
                          Card Payments
                        </h6>
                      </div>
                      <img
                        src={CardPayments}
                        alt=""
                        className="img-fluid rounded-4 w-100 object-fit-cover mb-4"
                        style={{ height: "150px" }}
                      />
                      <button
                        
                        className="btn btn-primary w-100 fw-normal py-8"
                      >
                        Coming Soon
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body p-4">
                      <div className="d-flex align-items-center justify-content-center mb-6">
                        <h6 className="mb-0 fw-semibold fs-4 mb-0">
                          Direct Bank
                        </h6>
                      </div>
                      <img
                        src={Bank}
                        alt=""
                        className="img-fluid rounded-4 w-100 object-fit-cover mb-4"
                        style={{ height: "150px" }}
                      />
                      <button
                        className="btn btn-primary w-100 fw-normal py-8"
                      >
                        Coming Soon
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewPayment
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default NewBilling;
