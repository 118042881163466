import { useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Modal, Button } from "react-bootstrap";
import ConfirmModal from "pages/components/confirmModal";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
const ScheduledTable = ({ data, isLoading }) => {
     const axiosPrivate = useAxiosPrivate();
     const navigate = useNavigate();
     const location = useLocation();
     const progress = new ProgressBar({ size: 5, color: "#020f46" });
  const [message, setMessage] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showContactsModal, setShowContactsModal] = useState(false);
  const [show, setShow] = useState(false);
  const [type, setType] = useState([]);
  const handleCloseModal = () => {
    setShowModal(false);
    setMessage([]);
  };
  const handleOpenModal = (message) => {
    setShowModal(true);
    setMessage(message);
  };
  const handleContactsCloseModal = () => {
    setShowContactsModal(false);
    setContacts([]);
  };
  const handleContactsOpenModal = (contacts) => {
    setShowContactsModal(true);
    setContacts(contacts);
  };
  
  const columns = useMemo(
    () => [
      {
        id: "contacts",
        header: "N0.contacts",
        size: 50,
        accessorFn: (row) => row.contacts.length,
      },
      {
        id: "type", //normal accessorKey
        header: "Route",
        size: 50,
        accessorFn: (row) =>
          row.type === "info"
            ? "Info SMS"
            : row.type === "number"
            ? "Number Route"
            : "",
      },
      {
        id: "status",
        header: "Status",
        size: 50,
        accessorFn: (row) =>
          row.status === "pending" ? (
            <span className="badge bg-info text-sm" style={{fontSize: "11px"}}>Pending</span>
          ) : row.status === "sent" ? (
            <span className="badge bg-success text-sm" style={{fontSize: "11px"}}>Sent</span>
          ) : (
            <span className="badge bg- text-sm" style={{fontSize: "11px"}}>Failed</span>
          ),
      },
      {
        accessorKey: "cost",
        header: "Cost",
        size: 50,
      },
      {
        accessorKey: "schedule_sent_time",
        header: "Schedule Time",
        size: 150,
      },
      {
        id: "action",
        header: "Action",
        size: 150,
        accessorFn: (row) => (
          <>
            <button
              className="btn btn-sm btn-primary m-1 text-sm"
              onClick={() => handleOpenModal(row.message)}
            >
              Message
            </button>
            <button
              className="btn btn-sm btn-primary m-1"
              onClick={() => handleContactsOpenModal(row.contacts)}
            >
              Contacts
            </button>
            <button
              className="btn btn-sm btn-primary m-1"
              onClick={() => {setShow(true); setContacts(row.contacts); setMessage(row.message); setType(row.type)}}
            >
              Resend
            </button>
          </>
        ),
      },
    ],
    []
  );
    const handleConfirm = async() => {
        try {
        progress.start();
        const newData = {
          message: message,
          recipients: contacts,
          type: type,
        };
        const controller = new AbortController();
        const response = await axiosPrivate.post("/sms/send/info", newData, {
          signal: controller.signal,
        });
        toast.success(response?.data?.messages);
        progress.finish();
        setType([]);
        setContacts([]);
        setMessage([]);
      } catch (error) {
        if (!error?.response) {
          toast.error("No server response");
          progress.finish();
        } else {
          console.log(error?.response);
          progress.finish();
          if (error?.response?.status === 401) {
            navigate("/", { state: { from: location }, replace: true });
          }
          toast.error(error?.response?.data?.messages, {
            style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
          });
        }
      }
    };

    const handleCancel = () => {
      setContacts([]);
      setMessage([]); 
    };
     const newContacts = contacts?.map((item) => item);
     const formattedContacts = newContacts?.join("\n");

  return (
    <>
      <Toaster />
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        className="table align-items-center p-0"
        enableColumnFilterModes
        enableColumnOrdering
        enableRowSelection
        enableGrouping
        enableStickyHeader
        state={{ isLoading: isLoading }}
        muiTableContainerProps={{ sx: { maxHeight: "400px" } }}
        initialState={{ density: "compact" }}
        muiTableHeadCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            fontWeight: "normal",
            fontSize: "12px",
            fontFamily: "Nunito",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "12px",
            fontFamily: "Nunito",
          },
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search",
          sx: { fontFamily: "Nunito", fontSize: "12px" },
        }}
      />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title className="text-center"> Message </Modal.Title>
          <button onClick={handleCloseModal} className="btn-close btn-danger">
            <i className="fa fa-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body className="container bg-dark">
          <code className="p-2 text-white">{message}</code>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="Button"
            className="btn btn-secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showContactsModal}
        backdrop="static"
        keyboard={false}
        onHide={handleContactsCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title className="text-center"> Contacts </Modal.Title>
          <button
            onClick={handleContactsCloseModal}
            className="btn-close btn-danger"
          >
            <i className="fa fa-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body className="container">
          <button className="btn btn-sm btn-primary">
            {" "}
            <i className="ti ti-clipboard"></i>Copy
          </button>
          <hr className="p-0" />
          <textarea
            type="text"
            className="form-control border-1 borde-dark shadow-md"
            defaultValue={formattedContacts}
            rows={10}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="Button"
            className="btn btn-secondary"
            onClick={handleContactsCloseModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ConfirmModal
        message="Are you sure you want to proceed to Resend This Message?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default ScheduledTable;
