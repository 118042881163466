import { Modal, Button } from "react-bootstrap";
const ShowContacts = ({ showContactsModal, handleContactsCloseModal, contacts }) => {
    const newContacts = contacts?.map((item) => item);
    const formattedContacts = newContacts?.join("\n");
  return (
    <>
      <Modal
        show={showContactsModal}
        backdrop="static"
        keyboard={false}
        onHide={handleContactsCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title className="text-center"> Contacts </Modal.Title>
          <button
            onClick={handleContactsCloseModal}
            className="btn-close btn-danger"
          >
            <i className="fa fa-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body className="container">
          <button className="btn btn-sm btn-primary">
            {" "}
            <i className="ti ti-clipboard"></i>Copy
          </button>
          <hr className="p-0" />
          <textarea
            type="text"
            className="form-control border-1 borde-dark shadow-md"
            defaultValue={formattedContacts}
            rows={10}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="Button"
            className="btn btn-secondary"
            onClick={handleContactsCloseModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
 
export default ShowContacts;