import Logo from "pages/assets/logos/logo-dark.png";
import { Link } from "react-router-dom";
import useLogout from "middlewares/hooks/useLogout";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isSidebarOpen, handleSidebarToggle }) => {
  const navigate = useNavigate();

   const logout = useLogout();
   const signOut = async () => {
     if (window.confirm("Are you sure you want to logout?")) {
       logout();
       localStorage.clear();
       navigate("/");
     }
   };

  return (
    <>
      <aside className={`left-sidebar sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div>
          <div className="brand-logo d-flex align-items-center justify-content-between">
            <Link to="/dashboard" className="text-nowrap logo-img">
              <img src={Logo} className="dark-logo" width="120" alt="logo" />
            </Link>
            <div
              className="close-btn d-lg-none d-block sidebartoggler cursor-pointer"
              id="sidebarCollapse"
              onClick={handleSidebarToggle}
            >
              <i className="ti ti-x fs-8 text-muted"></i>
            </div>
          </div>

          <nav className="sidebar-nav scroll-sidebar" data-simplebar>
            <ul id="sidebarnav">
              <li className="nav-small-cap">
                <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span className="hide-menu">SMS</span>
              </li>
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/dashboard"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-aperture"></i>
                  </span>
                  <span className="hide-menu">Dashboard</span>
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/info/bulk-sms"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-device-mobile-message"></i>
                  </span>
                  <span className="hide-menu">Info SMS</span>
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/number/bulk-sms"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-device-mobile-dollar"></i>
                  </span>
                  <span className="hide-menu">Number Route</span>
                </Link>
              </li>
              {/* <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/international-sms"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-world"></i>
                  </span>
                  <span className="hide-menu">International SMS</span>
                </Link>
              </li> */}
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/scheduled-sms"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-calendar-event"></i>
                  </span>
                  <span className="hide-menu">Scheduled SMS</span>
                </Link>
              </li>
              <li className="nav-small-cap">
                <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span className="hide-menu">Manage</span>
              </li>
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/manage/outbox"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-inbox"></i>
                  </span>
                  <span className="hide-menu">Outbox</span>
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/manage/contacts"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-address-book"></i>
                  </span>
                  <span className="hide-menu">Contacts</span>
                </Link>
              </li>
              <li className="nav-small-cap">
                <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span className="hide-menu">Billing</span>
              </li>
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/billing/buy-sms"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-credit-card"></i>
                  </span>
                  <span className="hide-menu">Buy SMS</span>
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/billing/history"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-businessplan"></i>
                  </span>
                  <span className="hide-menu">Billing History</span>
                </Link>
              </li>
              <li className="nav-small-cap">
                <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span className="hide-menu">Developer</span>
              </li>
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/developer/api-keys"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-api"></i>
                  </span>
                  <span className="hide-menu">API Keys</span>
                </Link>
              </li>
              <li className="nav-small-cap">
                <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span className="hide-menu">Accounts</span>
              </li>
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/accounts/profile"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-user-circle"></i>
                  </span>
                  <span className="hide-menu">Account</span>
                </Link>
              </li>
              {/* <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/password"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-lock"></i>
                  </span>
                  <span className="hide-menu">Password</span>
                </Link>
              </li> */}
            </ul>
            <div className="container row">
              <button onClick={signOut} className="btn btn-danger">
                <i className="ti ti-logout p-1"></i>
                Logout
              </button>
            </div>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
