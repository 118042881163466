import Logo from "pages/assets/logos/logo-dark.png";

import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../config/axios";
import toast, { Toaster } from "react-hot-toast";
import useAuth from "middlewares/hooks/useAuth";
const VerificationSignUp = () => {
 const [disabled, setDisabled] = useState(false);
 const nagivate = useNavigate();
 const { auth } = useAuth();

 const {
   register,
   handleSubmit,
   formState: { errors },
 } = useForm();

 const onSubmit = async (data) => {
   // console.log(data)
   try {
     setDisabled(true);
     await axios.post("/register/verify", data, {
       headers: { Authorization: auth?.token },
     });
     nagivate("/");
   } catch (error) {
     if (!error?.response) {
       toast("No server response");
       setDisabled(false);
     } else {
       setDisabled(false);
       toast(error?.response?.data?.messages);
     }
   }
 }; 

   const [timer, setTimer] = useState(60); // Initial timer set to 60 seconds (1 minute)
   const [resendDisabled, setResendDisabled] = useState(true);
   const [buttonDisabled, setButtonDisabled] = useState(false);

   useEffect(() => {
     let interval;
     if (timer > 0 && resendDisabled) {
       interval = setInterval(() => {
         setTimer((prevTimer) => prevTimer - 1);
       }, 1000);
     } else {
       setResendDisabled(false);
     }

     return () => clearInterval(interval);
   }, [timer, resendDisabled, buttonDisabled]);

   const handleResendClick = () => {
     setResendDisabled(true);
     setButtonDisabled(true); // Disable the button after it's clicked
     setTimer((prevTimer) => prevTimer + 60); // Add one minute (60 seconds) to the timer value
   };

   const formatTime = () => {
     const minutes = Math.floor(timer / 60);
     const seconds = timer % 60;
     return `${minutes < 10 ? "0" : ""}${minutes}:${
       seconds < 10 ? "0" : ""
     }${seconds}`;
   };
  return (
    <>
      <Toaster />
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <div className="position-relative overflow-hidden radial-gradient min-vh-100">
          <div className="position-relative z-index-5">
            <div className="row">
              <div className="col-lg-6 col-xl-8 col-xxl-9">
                <Link
                  to="/register/verify"
                  className="text-nowrap logo-img d-block px-4 py-9 w-100"
                >
                  <img src={Logo} width="180" alt="dark-logo" loading="lazy" />
                </Link>
                <div
                  className="d-none d-lg-flex align-items-center justify-content-center"
                  style={{ height: "calc(100vh - 80px)" }}
                >
                  <img
                    src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/backgrounds/login-security.svg"
                    alt="banner"
                    loading="lazy"
                    className="img-fluid"
                    width="500"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-xl-4 col-xxl-3">
                <div className="card mb-0 shadow-none rounded-0 min-vh-100 h-100">
                  <div className="d-flex align-items-center w-100 h-100">
                    <div className="card-body">
                      <div className="mb-5">
                        <h2 className="fw-bolder fs-7 mb-3">Verify Account</h2>
                        <p className="mb-0 ">
                          Please enter the verification code sent to your email
                          or mobile phone, if not received please click resend.
                        </p>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                          <label htmlFor="otp" className="form-label">
                            Verification Code
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="otp"
                            aria-describedby="otpcode"
                            placeholder="XXXXXX"
                            {...register("otp", { required: true })}
                          />
                          {errors?.otp?.type === "required" && (
                            <p className="text-small text-center text-danger help-block">
                              Please enter your verification otp from your email
                              address or contact
                            </p>
                          )}
                        </div>
                        <button
                          disabled={disabled}
                          className="btn btn-success text-white mb-2 w-100 py-8"
                        >
                          {!disabled && "Verify"}{" "}
                          {disabled && "Please Wait...."}
                        </button>
                      </form>
                      <p className="p-1 w-100">
                        Resend Verification code in: {formatTime()}
                      </p>
                      <button
                        onClick={handleResendClick}
                        disabled={resendDisabled || buttonDisabled}
                        className="btn btn-sm btn-info text-white w-100 py-8"
                      >
                        Resend Verification Code
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationSignUp;
