import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import useLogout from "middlewares/hooks/useLogout";

const Navbar = ({handleSidebarToggle}) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const controller = new AbortController();
  const [profile, setProfile] = useState([]);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);

  const getProfile = async () => {
    try {
      const response = await axiosPrivate.get("/accounts/profile", {
        signal: controller.signal,
      });
      setProfile(response.data.data.user);
      setIsLoadingProfile(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

    const logout = useLogout();
    const signOut = async () => {
      if (window.confirm("Are you sure you want to logout?")) {
        logout();
        localStorage.clear();
        navigate("/");
      }
    };

  return (
    <>
      <header className="app-header">
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
          <ul className="navbar-nav d-block d-sm-none">
            <li className="nav-item">
              <button
                className="nav-link sidebartoggler nav-icon-hover ms-n3"
                id="headerCollapse"
                onClick={handleSidebarToggle}
              >
                <i className="ti ti-menu-2"></i>
              </button>
            </li>
          </ul>

          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto"></ul>
            <div className="navbar-nav ml-auto float-right-buttons d-noned-sm-flex">
              <Link to="/billing/buy-sms" className="btn btn-sm btn-info mx-1">
                Buy SMS
              </Link>
              <Link
                to="/accounts/profile"
                className="btn btn-sm btn-danger mx-1"
              >
                SMS Plan
              </Link>
              <button className="btn btn-sm btn-dark mx-1">
                Account Balance (UGX): {isLoadingProfile && "loading ...."}{" "}
                {!isLoadingProfile && `${profile?.balance.toLocaleString()}`}
              </button>
            </div>
          </div>
          <div className="dropdown">
            <div
              className="rounded-circle overflow-hidden me-6"
              data-bs-toggle="dropdown"
            >
              <img
                src="assets/images/profile/user-1.jpg"
                alt=""
                width="40"
                height="40"
              />
            </div>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <Link to="/accounts/profile" className="dropdown-item">
                  <span>
                    <i className="ti ti-user p-1"></i>
                  </span>
                  Profile
                </Link>
              </li>
              <li>
                <Link to="/accounts/profile" className="dropdown-item">
                  <span>
                    <i className="ti ti-settings p-1"></i>
                  </span>
                  Settings
                </Link>
              </li>
              <li>
                <button onClick={signOut} className="dropdown-item">
                  <span>
                    <i className="ti ti-logout text-danger p-1"></i>
                  </span>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </nav>

        {/* Show the buttons on small and extra-small screens */}
        <div className="d-block d-sm-none btn-group">
          <Link to="/billing/buy-sms" className="btn btn-sm btn-info">
            Buy SMS
          </Link>
          <Link to="/accounts/profile" className="btn btn-sm btn-danger">
            SMS Plan
          </Link>
          <button className="btn btn-sm btn-dark">
            Account Balance (UGX): {isLoadingProfile && "loading ...."}{" "}
            {!isLoadingProfile && `${profile?.balance.toLocaleString()}`}
          </button>
        </div>
      </header>
    </>
  );
};

export default Navbar;
